import { Controller } from "@hotwired/stimulus"
import {TabulatorFull as Tabulator} from 'tabulator-tables';

export default class extends Controller {
  static targets = ["table","schedule"]
  static values = {data: Array}

  connect() {
    // console.log(this.dataValue)
    this.scheduleTarget.value = JSON.stringify(this.dataValue)
    //this.element.textContent = "Hello World!"
    // var sheets = [{rows:28,columns:8,data:[]},];
    //Build Tabulator
    //var table = new Tabulator("#example-table", {
    const tickElement = '<svg class="hi-outline hi-clock inline-block h-6 w-8 text-rose-400" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"></path></svg>'
    //<svg class="hi-outline hi-heart inline-block h-6 w-6 text-rose-600" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" stroke-linecap="round" stroke-linejoin="round"></path></svg>'
    this.table = new Tabulator(this.tableTarget, {
        height:"310px",
        selectableRangeAutoFocus: false,
        data: this.dataValue,
        // spreadsheet:true,
        // spreadsheetRows:50,
        // spreadsheetColumns:50,
        // spreadsheetColumnDefinition:{editor:"input", resizable:"header"},
        // spreadsheetSheets:sheets,
        // spreadsheetSheetTabs:true,
        columns:[
          {title:"月", field:"1", resizable:false, headerSort:false, formatter:"tickCross", formatterParams:{crossElement:"",tickElement: tickElement}},
          {title:"火", field:"2", resizable:false, headerSort:false, formatter:"tickCross", formatterParams:{crossElement:"",tickElement: tickElement}},
          {title:"水", field:"3", resizable:false, headerSort:false, formatter:"tickCross", formatterParams:{crossElement:"",tickElement: tickElement}},
          {title:"木", field:"4", resizable:false, headerSort:false, formatter:"tickCross", formatterParams:{crossElement:"",tickElement: tickElement}},
          {title:"金", field:"5", resizable:false, headerSort:false, formatter:"tickCross", formatterParams:{crossElement:"",tickElement: tickElement}},
          //{title:"祝", field:"8", width:40, resizable:false, headerSort:false, formatter:"tickCross", formatterParams:{crossElement:""}},
        ],
        // columns:[
        //   {title:"Name", field:"name", width:200, headerMenu:headerMenu}, //add menu to this column header
        // ],
        rowHeader:{field:"_id", hozAlign:"center", resizable:false, headerSort:false, frozen:true},
    
        editTriggerEvent:"dblclick", //change edit trigger mode to make cell navigation smoother
        editorEmptyValue:undefined, //ensure empty values are set to undefined so they arent included in spreadsheet output data
    
        //enable range selection
        selectableRange:1,
        selectableRangeColumns:true,
        selectableRangeRows:true,
        selectableRangeClearCells:true,
        
        //configure clipboard to allow copy and paste of range format data
        clipboard:true,
        clipboardCopyStyled:false,
        clipboardCopyConfig:{
            rowHeaders:false,
            columnHeaders:false,
        },
        clipboardCopyRowRange:"range",
        clipboardPasteParser:"range",
        clipboardPasteAction:"range",
    });
  }
  update() {
    const ranges = this.table.getRanges();
    let updateValue = 1;
    // let array = [];
    let changeData = this.dataValue
    // console.log(ranges[0]["_range"].start);
    // console.log(ranges[0]["_range"].end);
    if(ranges){
      if(changeData[ranges[0]["_range"].start.row][String(ranges[0]["_range"].start.col)]===1){
        updateValue = 0;
      }
      for(let i=ranges[0]["_range"].start.row; i<=ranges[0]["_range"].end.row;i++){
        for(let j=ranges[0]["_range"].start.col; j<=ranges[0]["_range"].end.col;j++){
          // array.push({id:i, "1":1})
          changeData[i][String(j)] = updateValue;
          // console.log(changeData[i][String(j)])
        }
      }
      // this.table.updateData(array);
      this.dataValue = changeData
      // console.log(this.dataValue)
      this.table.updateData(this.dataValue)
      this.scheduleTarget.value = JSON.stringify(this.dataValue)
    }
  }
  disconnect() {
    this.table.destroy()
  }
}
