import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["container", "backdrop", "panel", "closeButton", "class"]
  static values = {open: { type: Boolean, default: false}}

  initialize() {
    if(document.documentElement.clientWidth >= 1024) {
      // this.panelTarget.classList.remove("hidden")
      this.openValue = true
    } else {
      // this.openValue = false
      this.panelTarget.classList.add("hidden")
      //this.toggleableTarget.classList.add("hidden")
    }
    console.log(this.openValue)
    // super.connect();
    // console.log("the value of button : ", this.buttonTarget.value)
  }
  openValueChanged(value, previousValue) {
    if(value !== previousValue){
      if(this.openValue){
        this.show()
      } else {
        this.hide()
      }
    }
  }
  toggle() {
    // console.log(this.openValue)
    if(this.openValue){
      this.openValue = false
    } else {
      this.openValue = true
    }
  }
  show() {
    // console.log("show")
    this.classTargets.forEach(target => {
      target.classList.add("lg:ps-56")
    })
    // this.containerTarget.classList.remove("hidden")
    this.panelTarget.classList.remove("hidden")
    // enter(this.backdropTarget)
    // enter(this.closeButtonTarget)
    enter(this.panelTarget)
  }

  hide() {
    // console.log("hide")
    this.classTargets.forEach(target => {
      target.classList.remove("lg:ps-56")
    })
    Promise.all([
      // leave(this.backdropTarget),
      // leave(this.closeButtonTarget),
      leave(this.panelTarget)
    ]).then(() => {
      // this.containerTarget.classList.add("hidden")
      this.panelTarget.classList.add("hidden")
    })
  }
}