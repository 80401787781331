import { Toggle } from "tailwindcss-stimulus-components"

export default class Menu extends Toggle {
  static targets = ["class"]

  connect() {
    if(document.documentElement.clientWidth >= 1024) {
      this.openValue = true
    } else {
      this.openValue = false
      //this.toggleableTarget.classList.add("hidden")
    }
    // console.log(this.openValue)
    super.connect();
    // console.log("the value of button : ", this.buttonTarget.value)
  }
  openValueChanged() {
    if (this.openValue){
      this.classTargets.forEach(target => {
        target.classList.add("lg:ps-56")
      })
      // this.toggleableTarget.classList.remove("ltr:-translate-x-full")
      // this.toggleableTarget.classList.remove("rtl:translate-x-full")
      // this.toggleableTarget.classList.add("translate-x-0")
    } else {
      this.classTargets.forEach(target => {
        target.classList.remove("lg:ps-56")
      })
      // this.toggleableTarget.classList.add("ltr:-translate-x-full")
      // this.toggleableTarget.classList.add("rtl:translate-x-full")
      // this.toggleableTarget.classList.remove("translate-x-0")
    }
  }
}